import _ from 'lodash';
import moment from 'moment';
import { DATETIME_FORMAT, API_URL } from './Constants';

export const basepath = (parts: TemplateStringsArray, ...args: any[]) => {
	const path = _.flatten(_.zip(parts, args))
		.join('')
		.replace(/^\//, '');

	const url = new URL(process.env.BASE_PATH + path, window.location.origin);

	return url.toString();
};

export const apiUrl = (parts: TemplateStringsArray, ...args: any[]) => {
	const path = _.flatten(_.zip(parts, args))
		.join('')
		.replace(/^\//, '');

	const url = new URL(path, API_URL);

	return url.toString();
};

export class API {
	static async getToken() {
		const token = sessionStorage.getItem('token');
		const expiry = sessionStorage.getItem('tokenExpiry') || moment().format(DATETIME_FORMAT);
		if (!token || moment().isSameOrAfter(moment(expiry, DATETIME_FORMAT))) {
      window.location.href = basepath`/logout`;
      console.log('token missing or expired')
		}
		return sessionStorage.getItem('token')!;
	}

	static async fetchTokenForSignup() {
		const url = new URL('jv/v1/token', API_URL);

		try {
			return await fetch(url.toString(), {
				method: 'POST',
				headers: {
					Authorization: 'Basic bWVtYmVycy1wb3J0YWwta2ZnOnNpNzNqZGhza2sy',
					'Content-Type': 'application/x-www-form-urlencoded',
        },
        
				body: 'grant_type=client_credentials',
			});
		} catch (err) {
			console.log(err);
		}
	}

	static async get(path: string, params?: { [key: string]: string }) {
		const url = new URL(path.replace(/^\//, ''), API_URL);
		if (params) {
			url.search = new URLSearchParams(params).toString();
		}

		return await fetch(url.toString(), {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${await this.getToken()}`,
				'Content-Type': 'application/json',
			},
		});
	}

	static async post(path: string, body: any) {
		const url = new URL(path.replace(/^\//, ''), API_URL);
		return await fetch(url.toString(), {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${await this.getToken()}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
	}

	static async delete(path: string, body: any) {
		const url = new URL(path.replace(/^\//, ''), API_URL);
		return await fetch(url.toString(), {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${await this.getToken()}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
	}

	static async getSettings() {
		await this.get('/jv/v1/settings')
			.then(res => res.json())
			.then(data => {
				sessionStorage.setItem('paymentModes', JSON.stringify(data.payment_modes));
				sessionStorage.setItem('reservationPeriods', JSON.stringify(data.reservation_periods));
			})
			.catch(error => console.error(error));
  }
  
  static async getDeals() {
    let deals = JSON.parse(sessionStorage.getItem('deals') || '[]');
    if (!deals.length) {
      const res = await this.get('/jv/v1/deals');
      if (res.ok) {
        let data = await res.json();
        sessionStorage.setItem('deals', JSON.stringify(data));
        deals = data;
      } else {
        window.location.href = basepath`/logout`;
      }
    }
    return deals;
  }
}
