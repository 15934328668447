import { barcode } from 'pure-svg-code';
import React, { ComponentType } from 'react';

type props = {
	className?: string;
	content: string;
	options?: {
		barHeight?: number;
		width?: number;
		bgColor?: string;
		color?: string;
		showHRI?: boolean;
	};
};

export const Barcode: ComponentType<props> = ({ className, content, options }) => (
	<div className={className} dangerouslySetInnerHTML={{ __html: barcode(content, 'code128', { barHeight: 28, ...options }) }} />
);
