import { createContext, useContext, useState, useEffect } from 'react';
import { API, basepath } from './API';
import { useAsyncEffect } from './useAsyncEffect';

type Userdata = {
	name: string;
	info?: any;
};

export const UserContext = createContext<Userdata>({ name: sessionStorage.getItem('username') || 'user' });
UserContext.displayName = 'UserContext';

export const useUserContext = (withInfo = false) => {
	const user = useContext(UserContext);
  const [info, setInfo] = useState<any>(null);
  const getUserData = async () => {
    if (withInfo) {
			const res = await API.get('/jv/v1/members/me');
			if (res.ok) {
        let data = await res.json();
        setInfo(data);
			} else {
        window.location.href = basepath`/logout`;
      }
		}
  }

	useAsyncEffect(getUserData, []);
  
	return { ...user, info, getUserData };
};
