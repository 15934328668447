import React, { FC, useLayoutEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { LoginPage } from './core/login/LoginPage';
import { SignupPage } from './core/login/SignupPage';
import { HomePage } from './core/members/HomePage';
import { RewardsPage } from './core/members/RewardsPage';
import { Layout } from './core/common/Layout';
import { DealsPage } from './core/members/DealsPage';
import { CataloguePage } from './core/members/CataloguePage';
import { ResetPage } from './core/login/ResetPage';
import { TransactionsPage } from './core/members/TransactionsPage';

export const Controller: FC = () => {

  useLayoutEffect(() => {
    // sessionStorage.clear();
  }, []);

  return (
    <BrowserRouter basename={process.env.BASE_PATH}>
      <Switch>
        <Route
          path='/login'
          render={props => {
            const isLoggedIn = !!sessionStorage.getItem('token');

            if (isLoggedIn) {
              props.history.replace('/');
            }
            return <LoginPage />;
          }}
        />
        <Route path='/signup' component={SignupPage} />
        <Route
          path='/logout'
          render={({ history }) => {
            sessionStorage.clear();
            history.replace('/login' + window.location.hash);
            return null;
          }}
        />
        <Route path='/reset' component={ResetPage} />
        <LoginHandler>
          <Layout>
            <Route exact path='/' component={HomePage} />
            <Route path='/rewards' component={RewardsPage} />
            <Route path='/deals' component={DealsPage} />
            <Route path='/catalogue' component={CataloguePage} />
            <Route path='/transactions' component={TransactionsPage} />
            <Route path='/reset' component={ResetPage} />
          </Layout>
        </LoginHandler>
      </Switch>
    </BrowserRouter>
  );
};

export const LoginHandler: FC = ({ children }) => {
  //replace with actual login authentication
  const isLoggedIn = !!sessionStorage.getItem('token');
  return isLoggedIn ? <>{children}</> : <Redirect to='/login' />;
};