import React, { useLayoutEffect, useState } from "react"
import { useUserContext } from "../common/UserContext"
import _ from "lodash";
import { Voucher } from "./Voucher";
import moment from "moment";
import { API_DATETIME_FORMAT } from "../common/Constants";

export type VoucherType = {
  discount: string,
  effective_from: string,
  expires_on: string,
  image: boolean | string,
  reward_id: number,
  reward_type: string,
  title: string,
  voucher_no: string,
  voucher_status: string,
  description: string,
  tnc: string
}

export const RewardsPage = () => {
  const user = useUserContext(true);
  const [status, setStatus] = useState('active');
  const [activeVouchers, setActiveVouchers] = useState<VoucherType[]>([]);
  const [redeemedVouchers, setRedeemedVouchers] = useState<VoucherType[]>([]);
  const [expiredVouchers, setExpiredVouchers] = useState<VoucherType[]>([]);

  useLayoutEffect(() => {
    if (user.info) {
      let active: VoucherType[] = [];
      let redeemed: VoucherType[] = [];
      let expired: VoucherType[] = [];
      Object.values<VoucherType>(user.info.vouchers).sort((a: VoucherType, b: VoucherType) => {
        return moment(a.expires_on, API_DATETIME_FORMAT).isSameOrAfter(moment(b.expires_on,API_DATETIME_FORMAT)) ? 1 : -1;
      }).map((voucher: VoucherType) => {
        if (voucher.voucher_status === 'active') active.push(voucher);
        else if (voucher.voucher_status === 'redeemed') redeemed.push(voucher);
        else if (voucher.voucher_status === 'expired') expired.push(voucher);
      });
      setActiveVouchers(active);
      setRedeemedVouchers(redeemed);
      setExpiredVouchers(expired);
    }
  }, [user.info])

  return (
    <div>
      <h3 className="mt-3 pb-2">My Rewards</h3>
      <div className="d-flex w-100 justify-content-start">
        <div className={`tablink${status==='active'?' active':''}`} onClick={e => {setStatus('active')}}>Available ({activeVouchers.length})</div>
        <div className={`tablink ml-3${status==='redeemed'?' active':''}`} onClick={e => {setStatus('redeemed')}}>Used ({redeemedVouchers.length})</div>
        <div className={`tablink ml-3${status==='expired'?' active':''}`} onClick={e => {setStatus('expired')}}>Expired ({expiredVouchers.length})</div>
      </div>
      <div>
        {user.info && status === 'active' && activeVouchers.map((voucher: VoucherType) => (
          <Voucher key={voucher.voucher_no} voucher={voucher}/>
        ))}
        {status === 'active' && !activeVouchers.length && (
          <h5 className="mt-3">You have no available vouchers.</h5>
        )}
        {user.info && status === 'redeemed' && redeemedVouchers.map((voucher: VoucherType) => (
          <Voucher key={voucher.voucher_no} voucher={voucher}/>
        ))}
        {status === 'redeemed' && !redeemedVouchers.length && (
          <h5 className="mt-3">You have no redeemed vouchers.</h5>
        )}
        {user.info && status === 'expired' && expiredVouchers.map((voucher: VoucherType) => (
          <Voucher key={voucher.voucher_no} voucher={voucher}/>
        ))}
        {status === 'expired' && !expiredVouchers.length && (
          <h5 className="mt-3">You have no expired vouchers.</h5>
        )}
      </div>
    </div>
  )
}
