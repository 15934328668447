import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';
import { API_DATETIME_FORMAT, DATE_FORMAT } from '../common/Constants';
import { useUserContext } from '../common/UserContext';
import { basepath, API } from './../common/API';
import { useAsyncEffect } from '../common/useAsyncEffect';
import { DealType } from './DealsPage';
import { Deal } from './Deal';

export const HomePage = () => {
  const user = useUserContext(true);
  const [deals, setDeals] = useState<DealType[]>();
  const [showDeal, setShowDeal] = useState<DealType>();

  const lastTransaction = getLatestTransaction(user.info?.transactions);
  
  useAsyncEffect(async () => {
    setDeals(Object.values<DealType>(await API.getDeals()));
  }, [])
  
	return (
    <div>
			<div className='home-container mt-3'>
				<div className='Home__points' onClick={()=>window.location.href = basepath`/catalogue`}>
					<div className='big-text'>{countPoints(user.info?.points)}</div> Points
					{ countExpiringPoints(user.info?.points) > 0 && <div className='highlight-text'>
						{countExpiringPoints(user.info?.points)} Points expiring soon
					</div> }
				</div>
				<div className='Home__vouchers' onClick={()=>window.location.href = basepath`/rewards`}>
					<div className='big-text'>{countVouchers(user.info?.vouchers)}</div> Vouchers available
					{ countExpiringVouchers(user.info?.vouchers) > 0 && <div className='highlight-text'>
						{countExpiringVouchers(user.info?.vouchers)} Vouchers expiring soon
					</div> }
				</div>
				<div className='Home__deals' onClick={()=>window.location.href = basepath`/deals`}>
          <div className='big-text'>{deals?.length}</div> Exclusive deals
					{ countExpiringDeals(deals) > 0 && <div className='highlight-text'>{countExpiringDeals(deals)} Deals expiring soon</div> }
				</div>
				<div className='Home__transactions' onClick={()=>window.location.href = basepath`/transactions`}>
					<FontAwesomeIcon fixedWidth size='6x' className='mb-3' icon={faReceipt} />
					My Transactions
					<div className='highlight-text'>{lastTransaction && <>Last Transaction: {lastTransaction}</>}</div>
				</div>
			</div>

			<div className='mb-5' />

			{deals && deals.filter((d:DealType)=>d.featured).length ? (
        <>
          <div className='Deals__container'>
            <div className='Deals__title'>Featured Deals</div>
            {deals.filter((d:DealType)=>d.featured).slice(0,3).map((d:DealType, index) => (
              <div key={index} className={`Deals__img-${index+1}`} onClick={() => {
                setShowDeal(d);
              }}>
                <div className="deal-image cursor-pointer">
                  {d.image ? (
                    <img src={d.image+""} alt=""/>
                  ) : (
                    d.name
                  )}
                </div>
              </div>
            ))}
          </div>
          <Deal show={showDeal} setShow={setShowDeal} />
        </>
      ) : void 7}
    </div>
	);
};

function getMoments() {
	const todayIsBefore15 = moment().date() < 15;
	const startOfNextMonth = moment()
		.date(1)
		.add(1, 'month');
	const startOfNextNextMonth = moment()
		.date(1)
    .add(2, 'months');
  const endOfMonth = moment()
		.endOf('month');
  const endOfNextMonth = moment()
    .add(1, 'month')
    .endOf('month');

	return { todayIsBefore15, endOfMonth, endOfNextMonth };
}

function isPointsObject(x: any): x is { balance: number; expires_on: string } {
	return typeof x?.balance === 'number' && typeof x?.expires_on === 'string';
}

function countPoints(points: unknown): number {
	if (points instanceof Array) {
		return points
			.filter(isPointsObject)
			.map(o => {
        return o.balance || 0
      })
			.reduce((memo, val) => memo + val, 0);
	}
	return 0;
}

function countExpiringPoints(points: unknown): number {
	if (points instanceof Array) {
		const { todayIsBefore15, endOfMonth, endOfNextMonth } = getMoments();

		return points
			.filter(isPointsObject)
			.map(o => {
				const expiryMoment = moment(o.expires_on, API_DATETIME_FORMAT);
				if (expiryMoment.isValid() && expiryMoment.isSameOrBefore(todayIsBefore15 ? endOfMonth : endOfNextMonth, 'day')) {
					return o.balance || 0;
				}
				return 0;
			})
			.reduce((memo, val) => memo + val, 0);
	}
	return 0;
}

function countVouchers(vouchers: unknown): number {
	if (typeof vouchers === 'object' && vouchers) {
		return Object.values(vouchers).filter((voucher) => {
      // console.log(voucher.title, moment(voucher.expires_on, API_DATETIME_FORMAT).format(DATE_FORMAT), moment().format(DATE_FORMAT), moment(voucher.expires_on, API_DATETIME_FORMAT).isSameOrAfter(moment(),'day'))
      return voucher.voucher_status === 'active' && moment(voucher.expires_on, API_DATETIME_FORMAT).isSameOrAfter(moment(),'day')
    }).length;
	}

	return 0;
}

function isVoucherObject(x: any): x is { expires_on: string } {
	return typeof x?.expires_on === 'string';
}

function countExpiringVouchers(vouchers: unknown): number {
	let voucherValues: unknown[] | null = null;

	if (typeof vouchers === 'object' && vouchers) {
		voucherValues = Object.values(vouchers).filter(voucher=>{
      return voucher.voucher_status === 'active' && moment(voucher.expires_on, API_DATETIME_FORMAT).isSameOrAfter(moment(),'day')
    });
	}

	if (vouchers instanceof Array) {
		voucherValues = vouchers;
	}

	if (voucherValues) {
		const { todayIsBefore15, endOfMonth, endOfNextMonth } = getMoments();

		return voucherValues.filter(isVoucherObject).filter(o => {
      const expiryMoment = moment(o.expires_on, API_DATETIME_FORMAT);
      // console.log(expiryMoment.format(DATE_FORMAT),expiryMoment.isSameOrBefore(todayIsBefore15 ? endOfMonth : endOfNextMonth, 'day'))
			return expiryMoment.isValid() && expiryMoment.isSameOrBefore(todayIsBefore15 ? endOfMonth : endOfNextMonth, 'day');
		}).length;
	}
	return 0;
}

function isTrxnObject(x: any): x is { transaction_datetime: string } {
	return x?.transaction_status === 'active';
}

function getLatestTransaction(transactions: unknown): string | null {
	let trxnValues: any[] | null = null;

	if (typeof transactions === 'object' && transactions) {
		trxnValues = Object.values(transactions);
	}

	if (transactions instanceof Array) {
		trxnValues = transactions;
	}

	if (trxnValues) {
		return (
			trxnValues.filter(isTrxnObject).reduce((memo, value) => {
				if (!value.transaction_datetime) return memo
				if (!memo) return value.transaction_datetime;
				const currentMoment = moment(value.transaction_datetime, API_DATETIME_FORMAT);
				if (currentMoment.isValid() && currentMoment.isAfter(moment(memo, API_DATETIME_FORMAT))) {
					return value.transaction_datetime;
				} else {
					return memo;
				}
			}, null as null | string)
		);
	}
	return null;
}

function countExpiringDeals(deals: DealType[] | undefined) {
  if (deals?.length) {
    const { todayIsBefore15, endOfMonth, endOfNextMonth } = getMoments();
    return deals.filter(d => {
      const expiryMoment = moment(d.datetime_to, API_DATETIME_FORMAT);
      // console.log(expiryMoment.format(DATE_FORMAT),expiryMoment.isSameOrBefore(todayIsBefore15 ? endOfMonth : endOfNextMonth, 'day'))
			return expiryMoment.isValid() && expiryMoment.isSameOrBefore(todayIsBefore15 ? endOfMonth : endOfNextMonth, 'day');
		}).length;
	}
	
	return 0;
}