import { AttractedErrorMessage, AttractedInput, MagneticForm, MagneticState } from 'magnetic-forms';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { KeyboardEvent, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, FormText, InputGroup, InputGroupAddon } from 'reactstrap';
import { apiUrl } from '../common/API';
import { DATETIME_FORMAT } from '../common/Constants';
import { requiredStr, requiredPhoneNum } from '../common/Validations';

const kfgLogo: string = require('../../images/KFG-Logo-placeholder.png');
const lock: string = require('../../images/lock.svg');
const phone: string = require('../../images/phone.svg');

const Authorization = 'Basic bWVtYmVycy1wb3J0YWwta2ZnOnNpNzNqZGhza2sy';

class LoginState {
	fields = {
		username: new MagneticState({ schema: requiredPhoneNum }),
		password: new MagneticState({ schema: requiredStr }),
	};

	async login() {
		try {
			const res = await fetch(apiUrl`/jv/v1/token/`, {
				method: 'POST',
				headers: {
					Authorization,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams({ grant_type: 'password', username: this.fields.username.value, password: this.fields.password.value }),
			});

			if (res.ok) {
				const result = await res.json();
				if (result.access_token && result.expires_in) {
					sessionStorage.setItem('token', result.access_token);
					sessionStorage.setItem(
						'tokenExpiry',
						moment()
							.add(result.expires_in, 's')
							.format(DATETIME_FORMAT)
					);
					sessionStorage.setItem('username', result.user_name ?? this.fields.username.value);
					this.fields.username.value = '';
					this.fields.password.value = '';
				} else {
					window.location.href = `${window.location.origin}${process.env.BASE_PATH}logout`;
				}

				return true;
			} else {
				alert('Incorrect User ID or Password!');
			}
		} catch (error) {
			console.log(error);
			alert('Cannot connect to server.');
		}
	}
}

export const LoginPage = observer(() => {
	const [state] = useState(() => new LoginState());
	const history = useHistory();

	useLayoutEffect(() => {
		document.body.classList.add('login-darker');
		return () => document.body.classList.remove('login-darker');
	}, []);

	const loginSubmitHandler = () => {
		const formValid = Object.values(state.fields)
			.map(f => f.validate())
			.every(v => v === true);

		if (!formValid) {
			return;
		}
		state.login().then(isValid => {
			if (isValid) {
				history.push('/');
			}
		});
	};

	const loginOnEnter = (e: KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && loginSubmitHandler();

	return (
		<MagneticForm fields={state.fields}>
			<div className='login'>
				<div className='logo-container'>
					<img src={kfgLogo} />
				</div>
				<div className='rewards'>REWARDS</div>
				<div className='login-text'>LOGIN</div>
				<div className='mx-2'>
					<InputGroup className='mt-3'>
						<InputGroupAddon className='mobile-prepend' addonType='prepend'>
							+65
						</InputGroupAddon>
						<AttractedInput name='username' className='form-control login-mobile' placeholder='mobile number' onKeyDown={loginOnEnter} />
						<InputGroupAddon addonType='append'>
							<div className='phone-container'>
								<img src={phone} />
							</div>
						</InputGroupAddon>
					</InputGroup>
					<FormText color='danger'>
						<AttractedErrorMessage forceRender name='username' />
					</FormText>

					<InputGroup className='mt-2'>
						<AttractedInput name='password' type='password' className='form-control login-mobile' placeholder='password' onKeyDown={loginOnEnter} />
						<InputGroupAddon addonType='append'>
							<div className='phone-container'>
								<img src={lock} />
							</div>
						</InputGroupAddon> 
					</InputGroup>
					<FormText color='danger'>
						<AttractedErrorMessage forceRender name='password' />
					</FormText>
					<Button className='mt-2' color='primary' block onClick={loginSubmitHandler}>
						SIGN IN
					</Button>
          <div className='forgot'>
            <a href='./signup'> Register</a>
            {' | '}
            <a href='./reset'>Forgot Password</a>
          </div>
				</div>
			</div>
		</MagneticForm>
	);
});
