import { Modal, ModalHeader, ModalBody } from "reactstrap"
import React from "react"
import moment = require("moment")
import { API_DATETIME_FORMAT, DATE_FORMAT_SLASH } from "../common/Constants"
import { DealType } from "./DealsPage"

type props = {
  show: DealType | undefined
  setShow: (deal: DealType | undefined) => void

}

export const Deal = (props:props) => {
  return (
    <Modal isOpen={props.show != undefined} toggle={()=>props.setShow(undefined)}>
      <ModalHeader toggle={()=>props.setShow(undefined)} />
      <ModalBody>
        <h4>{props.show?.name}</h4>
        <span>Valid till {moment(props.show?.datetime_to, API_DATETIME_FORMAT).format(DATE_FORMAT_SLASH)}</span>
        <div className={props.show?.image ? "deal-image" : "deal-image no-image"}>
          {props.show?.image ? (
            <img src={props.show?.image+""} alt=""/>
          ) : (
            props.show?.name
          )}
        </div>
        <div className="mb-2" dangerouslySetInnerHTML={{__html: props.show?.description || ''}}></div>
        <h5 className="mt-5">Terms &amp; Conditions</h5>
        <div className="mb-2" dangerouslySetInnerHTML={{__html:props.show?.tnc || ''}}></div>
      </ModalBody>
    </Modal>
  )
}