import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentType } from 'react';
import { useLocation } from 'react-router';
import { Tooltip } from 'reactstrap';
// import { set } from 'mobx';
import { useUserContext } from '../common/UserContext';
import { classes } from './classes';

type NavLink = {
	href: string;
	icon: IconDefinition;
	label: string;
	onClick?: () => void;
};

const links: NavLink[] = [
	{ href: './',             icon: faUser,     label: 'My Account' },
	{ href: './rewards',      icon: faGift,     label: 'My Rewards' },
	{ href: './transactions', icon: faReceipt,  label: 'My Transactions' },
	{ href: './catalogue',    icon: faThLarge,  label: 'Rewards Catalogue' },
	{ href: './deals',        icon: faTag,      label: 'Deals & Offers' },
	{ href: './reset',       icon: faKey, label: 'Change Password' },
	{ href: './logout',       icon: faDoorOpen, label: 'Sign Out' },
];

export const Navigation: ComponentType = () => {
	const user = useUserContext(true);
	const location = useLocation();

	//clipboard
	// const [clipboard, copyToClipboard] = useClipboard()
	// const toClipboard = user.info && user.info.referral_code
	  
	//tooltip 
	// const [tooltipOpen, setTooltipOpen] = useState(false);
	// const _onClick = () => {
	// 	copyToClipboard(toClipboard);
	// 	setTooltipOpen(!tooltipOpen);
	// 	setTimeout(()=> setTooltipOpen(tooltipOpen), 2000)
	//   };
	
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  
  const copyCode = () => {
    // copyToClipboard(toClipboard); 
    // setTooltipOpen(!tooltipOpen);
  }

	return (
		<>
			<div className='Base__navbar pt-3'>
				<span className="alignSpan">
					<FontAwesomeIcon fixedWidth className='mr-2' icon={faUsers} />
					{/* &nbsp;My Referral Code: <span className='clipClick' id="tooltip" onClick={_onClick}>{user.info && user.info.referral_code}</span>
						<Tooltip placement="top" isOpen={tooltipOpen} target="tooltip"> */}
					&nbsp;My Referral Code: <span className='clipClick' id="tooltip" onClick={copyCode}>{user.info && user.info.referral_code}</span>
						{/* <Tooltip placement="top" isOpen={tooltipOpen} target="tooltip">
							Text Copied
						</Tooltip> */}
				</span>
				{links.map(({ href, icon, label }) => (
					<a key={label}
						href={href}
						className={classes('Base__navlink', location.pathname === href.replace('./','/') && 'active')}
						onClick={e => location.pathname === href &&  e.preventDefault()}
					>
						<FontAwesomeIcon fixedWidth className='mr-2' icon={icon} /> {label}
					</a>
				))}
			</div>
		</>
	);
};
