import React, { FC, useState } from 'react';
import { LabeledInputState, LabeledInput, UnlabeledInput, UnlabeledInput2 } from '../common/LabeledInput';
import { requiredStr, requiredPhoneNum, requiredEmail, requiredSelect, optionalStr, requiredMin4Char } from '../common/Validations';
import { Button, FormText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { API, basepath } from '../common/API';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import _ from 'lodash';
import { observable, reaction } from 'mobx';
import { FakeCheckbox } from '../common/FakeCheckbox';
import { DATETIME_FORMAT } from '../common/Constants';
import { API_URL } from './../common/Constants';
import { load } from 'recaptcha-v3';

const kfgLogo: string = require('../../images/KFG-Logo-placeholder.png');

class SignupState {
	textFields = { 
		name: new LabeledInputState(requiredStr),
		phone: new LabeledInputState(requiredPhoneNum),
		otp: new LabeledInputState(requiredStr),
		email: new LabeledInputState(requiredEmail),
		password: new LabeledInputState(requiredMin4Char),
		password_c: new LabeledInputState(requiredMin4Char),
		bMon: new LabeledInputState(requiredSelect),
		bYr: new LabeledInputState(requiredSelect),
		gender: new LabeledInputState(requiredSelect),
		stay: new LabeledInputState(requiredSelect),
		diet: new LabeledInputState(requiredSelect),
		know: new LabeledInputState(requiredSelect),
    others: new LabeledInputState(optionalStr),
    referral_code: new LabeledInputState(optionalStr)
	};
	@observable bySms = true;
	@observable byEmail = true;
  @observable agree = false;
  @observable agreeTouched = false;
  @observable otpCooldown = 0;
  @observable existingNumber = '';
	@observable options:
		| {
				preferences: Record<string, string>;
				referral_source: Record<string, string>;
				communication_preferences: Record<string, string>;
				address: Record<string, string>;
		  }
		| undefined;
	constructor() {
		this.fetchChoices();

		reaction(
			() => [this.textFields.password.value, this.textFields.password_c.value],
			([pass, pass_c]) => {
				if (pass.length > 0 && pass_c.length > 0 && pass !== pass_c) {
					this.textFields.password_c.overrideError = 'Passwords do not match.';
				} else {
					this.textFields.password_c.overrideError = null;
				}
			}
    );
    
    reaction(
			() => [this.textFields.phone.value, this.existingNumber],
			([phone, existing]) => {
				if (phone.length > 0 && existing.length > 0 && phone === existing) {
					this.textFields.phone.overrideError = 'Mobile number already in use. Please use another number, or proceed to <a href="./login">login</a>.';
				} else {
					this.textFields.phone.overrideError = null;
				}
			}
		);
	}

	get isValid(): boolean {
		return _.every(this.textFields, f => (f.validate(), !f.handler.invalid)) && this.agree;
	}

	get detached(): API_Post_RegisterMember {
		return {
			name: this.textFields.name.value,
			phone: this.textFields.phone.value,
			email: this.textFields.email.value,
			birth_month: parseInt(this.textFields.bMon.value),
			birth_year: parseInt(this.textFields.bYr.value),
			gender: this.textFields.gender.value,
			area: this.textFields.stay.value,
			preferences: this.textFields.diet.value,
			referral_source: this.textFields.know.value,
      referral_source_others: this.textFields.others.value,
      referral_code: this.textFields.referral_code.value,
			communication_preferences: _.compact([this.bySms && 'SMS', this.byEmail && 'Email']),
			otp: this.textFields.otp.value,
			password: this.textFields.password.value,
		};
	}

	submit = async () => {
		if (!this.isValid) return false;

		const reCaptcha = await load('6LdKF7EUAAAAANusPfnkXy8FCmV9pIyz7RVtOmYk');
    const rToken = await reCaptcha.execute('submit');
		const data = { ...this.detached, 'g-recaptcha-response': rToken };
		// const res = await API.post('/jv/v1/members', data);
		const res = await fetch(`${API_URL}jv/v1/members`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${await this.getSignupToken()}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
		const result = await res.json();
		if (!res.ok) {
			alert(result.message);
		} else {
			alert(`Member created. Membership number: ${result}`);
		}
		return res.ok;
	};

	getSignupToken = async () => {
		const token = sessionStorage.getItem('signupToken');
		const expiry = sessionStorage.getItem('signupTokenExpiry') || moment().format(DATETIME_FORMAT);
		if (token && moment().isBefore(moment(expiry, DATETIME_FORMAT))) {
			return token;
		} else {
			let response = await API.fetchTokenForSignup();
			if (response && response.ok) {
				let res = await response.json();
				sessionStorage.setItem('signupToken', res.access_token);
				sessionStorage.setItem(
					'signupTokenExpiry',
					moment()
						.add(res.expires_in, 's')
						.format(DATETIME_FORMAT)
				);
				return res.access_token;
			} else {
				alert('An error occurred');
			}
		}
	};

	fetchChoices = async () => {
		fetch(`${API_URL}jv/v1/choices`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${await this.getSignupToken()}`,
			},
		})
			.then(res => res.json())
			.then(data => {
				this.options = data;
			});
  };
  
}

type API_Post_RegisterMember = {
	name: string;
	phone: string;
	email: string;
	birth_month: number;
	birth_year: number;
	gender: string;
	area: string;
	preferences: string;
	referral_source: string;
	referral_source_others?: string;
	communication_preferences: string[];
	otp: string;
  password: string;
  referral_code?: string;
};

export const SignupPage: FC = observer(() => {
	const monthsAry = moment.monthsShort();
	const yearsAry = _(1900)
		.rangeRight(((moment().year())-18) + 1)
		.map(_.toString)
		.value();
	const [current] = useState<SignupState>(() => new SignupState());
  const knowIsOthers = current.textFields.know.value === 'Others';
  let timer: any;
  const [showTnc, setShowTnc] = useState(false);
  const [showPdpa, setShowPdpa] = useState(false);

  const startOtpTimer = () => {
    current.otpCooldown = 60;
    timer = setInterval(() => {
      if (current.otpCooldown <= 0) {
        clearInterval(timer);
      } 
      current.otpCooldown = current.otpCooldown - 1;
    }, 1000);
  }

  const stopOtpTimer = () => {
    clearInterval(timer);
    current.otpCooldown = 0;
  }

	const getOtp = async () => {
    startOtpTimer();
		load('6LdKF7EUAAAAANusPfnkXy8FCmV9pIyz7RVtOmYk').then(reCaptcha => {
			reCaptcha.execute('getOtp').then(async rToken => {
				let response = await fetch(
					`${API_URL}jv/v1/otp/${current.textFields.phone.value}?phone=${current.textFields.phone.value}&g-recaptcha-response=${rToken}&purpose=registration`,
					{
						headers: {
							Authorization: `Bearer ${sessionStorage.getItem('signupToken')}`,
						},
					}
				);
				if (!response.ok) {
          let error = await response.json();
          if (error.code === 'exists') {
            current.existingNumber = current.textFields.phone.value;
            stopOtpTimer();
          }
				}
			});
		});
  };
  
  const validateAll = () => {
    _.forEach(current.textFields, (field: LabeledInputState) => {
      field.touched = true;
      field.validate();
    });
    current.agreeTouched = true;
  }

	return (
		<div className='signup'>

			<div className='logo-container cursor-pointer mt-4' onClick={() => location.href = basepath`/login`}>
				<img src={kfgLogo} />
			</div>
			<div className='rewards'>REWARDS</div>
			<div className='login-text'>SIGN UP</div>
			<div className='signup-form'>
				<UnlabeledInput className='mb-4' placeholder='Name' errorMessageAbsolute {...current.textFields.name.handler} />
				<div className='phone'>
          <UnlabeledInput className='mr-2' placeholder='Phone' hideErrorMessage {...current.textFields.phone.handler} htmlError/>
          <Button type='button' block color='primary' 
            className='text-uppercase' 
            onClick={getOtp} 
            disabled={
              !current.textFields.phone.touched ||
               current.textFields.phone.handler.invalid || 
               (current.otpCooldown > 0) 
            }>
            {current.otpCooldown > 0 ? 
              <small style={{textTransform: 'none', color: 'black', display: 'block'}}>Please wait {current.otpCooldown} seconds before requesting for a new OTP.</small> 
              : 'SEND OTP'
            }
					</Button>
				</div>
        <FormText color='danger' >
          {current.textFields.phone.handler.invalid ? <span dangerouslySetInnerHTML={{__html: current.textFields.phone.handler.errorMessage}}></span> : <>&nbsp;</>}{' '}
        </FormText>
				<form autoComplete='off' onSubmit={e => e.preventDefault()}>
					<UnlabeledInput className='mb-4' type='text' placeholder='OTP' errorMessageAbsolute {...current.textFields.otp.handler} maxLength={4} />
				</form>
        <form autoComplete='off' onSubmit={e => e.preventDefault()}>
          <UnlabeledInput className='mb-4' placeholder='Email' errorMessageAbsolute {...current.textFields.email.handler} />
        </form>
        <form autoComplete='off' onSubmit={e => e.preventDefault()}>
          <UnlabeledInput className='mb-4' type='password' placeholder='Password' errorMessageAbsolute {...current.textFields.password.handler} />
        </form>
        <form autoComplete='off' onSubmit={e => e.preventDefault()}>
          <UnlabeledInput className='mb-4' type='password' placeholder='Re-enter Password' errorMessageAbsolute {...current.textFields.password_c.handler}/>
        </form>
				<div className='birth-gender mb-4'>
					<UnlabeledInput className='mr-2' type='select' placeholder='Birth Month' errorMessageAbsolute {...current.textFields.bMon.handler}>
						<option disabled value='' >Birth Month</option>
						{monthsAry.map((mon, key) => (
							<option key={mon} value={key + 1} children={mon} />
						))}
					</UnlabeledInput>
					<UnlabeledInput className='mr-2' type='select' placeholder='Birth Year' errorMessageAbsolute {...current.textFields.bYr.handler}>
            <option disabled value='' >Birth Year</option>
						{yearsAry.map(mon => (
							<option key={mon} value={mon} children={mon} />
						))}
					</UnlabeledInput>
					<UnlabeledInput type='select' placeholder='Gender' errorMessageAbsolute {...current.textFields.gender.handler}>
            <option disabled value='' >Gender</option>
						<option value='f'>Female</option>
						<option value='m'>Male</option>
						<option value='o'>Others</option>
					</UnlabeledInput>
				</div>
				<UnlabeledInput className='mb-4' type='select' placeholder='Which area do you stay?' errorMessageAbsolute {...current.textFields.stay.handler}>
          <option disabled value='' >Which area do you stay?</option>
					{current.options &&
						_.map(current.options.address, (val, key) => (
							<option key={key} value={key}>
								{val}
							</option>
						))}
				</UnlabeledInput>
				<UnlabeledInput className='mb-4' type='select' placeholder='Dietary Preference' errorMessageAbsolute {...current.textFields.diet.handler}>
          <option disabled value='' >Dietary Preference</option>
					{current.options &&
						_.map(current.options.preferences, (val, key) => (
							<option key={key} value={key}>
								{val}
							</option>
						))}
				</UnlabeledInput>
				<UnlabeledInput
					className='mb-4'
					type='select'
					placeholder='How do you know about our loyalty programme?'
					errorMessageAbsolute
					{...current.textFields.know.handler}
				>
					<option disabled value='' >How do you know about our loyalty programme?</option>
					{current.options &&
						_.map(current.options.referral_source, (val, key) => (
							<option key={key} value={key}>
								{val}
							</option>
						))}
				</UnlabeledInput>
				{knowIsOthers && (
					<div className='mb-4'>
						<UnlabeledInput placeholder='Please specify' errorMessageAbsolute {...current.textFields.others.handler}></UnlabeledInput>
					</div>
        )}
        <UnlabeledInput2 className='mb-4' placeholder='Referral Code' errorMessageAbsolute {...current.textFields.referral_code.handler} />
				<p className='mb-1 mt-3'>Get updated on members’ privileges and promotions via the following ways:</p>
				<div className='comm-prefs mb-3'>
					<FakeCheckbox clickLabel label='SMS' checked={current.bySms} onClick={() => (current.bySms = !current.bySms)} />
					<FakeCheckbox clickLabel label='Email' checked={current.byEmail} onClick={() => (current.byEmail = !current.byEmail)} />
				</div>
				<div className='mb-4' style={{position: 'relative'}}>
					<FakeCheckbox checked={current.agree} onClick={() => (current.agree = !current.agree)}>
						I agree with the <a href="#" onClick={e => { 
                e.preventDefault();
                setShowTnc(true);
              }}>Terms &amp; Conditions</a> and <a href='#' onClick={e => {
                e.preventDefault();
                setShowPdpa(true);
              }}>Privacy Policy</a> of Kingdom Food Group.
						<span className='text-danger'>*</span>
					</FakeCheckbox>
          <FormText color='danger' style={{ position: 'absolute', bottom: -20, left: 0, right: 0, whiteSpace: 'nowrap' }}>
              {(current.agreeTouched && !current.agree) ? 'You must agree to the terms & conditions and privacy policy to proceed.' : <>&nbsp;</>}{' '}
            </FormText>
				</div>
				<Button
					block
					color='primary'
					// disabled={!current.isValid}
					onClick={async () => {
            validateAll();
						const success = await current.submit();
						if (success) {
							sessionStorage.removeItem('signupToken');
							sessionStorage.removeItem('signupTokenExpiry');
							window.location.href = basepath`/`;
						}
					}}
				>
					REGISTER
				</Button>
			</div>
      <Modal size="lg" isOpen={showTnc} toggle={()=>setShowTnc(false)}>
        <ModalHeader toggle={()=>setShowTnc(false)}></ModalHeader>
        <ModalBody>
          <iframe style={{width:'100%', height:'80vh'}} src="https://kingdomfood.sg/terms-of-use-privacy-policy-for-member-portal/" />
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={showPdpa} toggle={()=>setShowPdpa(false)}>
        <ModalHeader toggle={()=>setShowPdpa(false)}></ModalHeader>
        <ModalBody>
          <iframe style={{width:'100%', height:'80vh'}} src="https://kingdomfood.sg/terms-of-use-privacy-policy-for-member-portal/" />
        </ModalBody>
      </Modal>
		</div>
	);
});


