import { AttractedErrorMessage, AttractedInput, DefaultMagneticComponentsProvider, MagneticForm, MagneticState, MagneticTextbox, MagneticSelectState, MagneticDropdown, AttractedSelect } from 'magnetic-forms';
import React, { ComponentType, useState } from 'react';
import { Button, FormText, Label } from 'reactstrap';
import { requiredEmail, requiredPhoneNum, requiredStr, optionalStr } from '../common/Validations';
import moment from 'moment';
import _ from 'lodash';
import { API } from './../common/API';
import { useUserContext } from '../common/UserContext';
import { useLayoutEffect } from 'react';
import { FakeCheckbox } from '../common/FakeCheckbox';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';

const yearsAry = _(1900)
	.rangeRight(moment().year() + 1)
	.map(_.toString)
	.map(value => ({ label: value, value }))
	.value();

class ProfileFormState {
	static useState() {
		return useState(() => new this())[0];
  };
  
  constructor() {
    this.fetchChoices();
  }

  @observable bySms = false;
  @observable byEmail = false;
  @observable otpCooldown = 0;

	fields = {
		name: new MagneticState({ schema: requiredStr }),
		phone: new MagneticState({ schema: requiredPhoneNum }),
		email: new MagneticState({ schema: requiredEmail }),
		otp: new MagneticState({ schema: optionalStr }),
		bMonth: new MagneticSelectState({
			placeholder: '',
      schema: requiredStr,
      disabled: true,
			options: moment.monthsShort().map((month, index) => ({ label: month, value: String(index + 1) }))
		}),
		bYear: new MagneticSelectState({
			placeholder: '',
			schema: requiredStr,
			options: yearsAry,
		}),
		gender: new MagneticSelectState({
			placeholder: '',
			schema: requiredStr,
			options: [
				{ label: 'Female', value: 'f' },
				{ label: 'Male', value: 'm' },
				{ label: 'Others', value: 'o' },
			],
		}),
		area: new MagneticSelectState({
			placeholder: '',
      schema: requiredStr,
		}),
		diet: new MagneticSelectState({
			placeholder: '',
			schema: requiredStr,
		}),
		know: new MagneticSelectState({
			placeholder: '',
			schema: requiredStr,
    }),
    others: new MagneticState({ schema: optionalStr }),
  };
  
  fetchChoices = async () => {
    API.get('/jv/v1/choices').then(async res => {
      let data = await res.json();
      this.fields.area.options = _.map(data.area, (val, key) => {
        return {label: val, value: key}});
      this.fields.diet.options = _.map(data.preferences, (val, key) => {
        return {label: val, value: key}});
      this.fields.know.options = _.map(data.referral_source, (val, key) => {
        return {label: val, value: key}});
    });
  }

  get isValid(): boolean {
		return _.every(this.fields, f => f._valid);
  }
  
}

export const ProfileForm: ComponentType = observer(() => {
  const state = ProfileFormState.useState();
  const user = useUserContext(true);
  const knowIsOthers = state.fields.know.value === 'Others';
  const phoneChanged = state.fields.phone.value !== user.info?.phone;
  let existingPhoneNums: string[] = [];
  let timer: NodeJS.Timeout;
  
  const submit = async () => {
    if (!state.isValid) return;
    let res = await API.post('/jv/v1/memberships/'+user.info.membership_no, getData());
    const result = await res.json();
    if (!res.ok) {
      alert(result.message);
    } else {
      alert('Member updated.')
    }
    return res.ok;
  }

  const getData = () => {
    let result = {
      name: state.fields.name.value,
      phone: state.fields.phone.value,
      otp: state.fields.otp.value,
      email: state.fields.email.value,
      birth_year: state.fields.bYear.value,
      gender: state.fields.gender.value,
      area: state.fields.area.value,
      preferences: state.fields.diet.value,
      referral_source: state.fields.know.value,
      referral_source_others: state.fields.others.value,
      communication_preferences: getCommPref()
    }
    if (state.fields.otp.value === '') {
      delete result.otp;
    }
    if (!knowIsOthers) {
      delete result.referral_source_others;
    }
    return result;
  }

  const getCommPref = () => {
    let result = [];
    if (state.bySms) result.push('SMS');
    if (state.byEmail) result.push('Email');
    return result.length ? result : [];
  }

  const updateFields = () => {
    state.fields.name.value = user.info.name;
    state.fields.phone.value = user.info.phone;
    state.fields.email.value = user.info.email;
    state.fields.bMonth.value = user.info.birth_month;
    state.fields.bYear.value = user.info.birth_year;
    state.fields.gender.value = user.info.gender;
    state.fields.area.value = user.info.area;
    state.fields.diet.value = user.info.preferences;
    if (user.info.communication_preferences) {
      if (user.info.communication_preferences.includes('SMS')) state.bySms = true;
      if (user.info.communication_preferences.includes('Email')) state.byEmail = true;
    }

    if (_.find(state.fields.know.options, {label:user.info.referral_source})) {
      state.fields.others.value = '';
      state.fields.know.value = user.info.referral_source;
    } else {
      state.fields.know.value = 'Others';
      state.fields.others.value = user.info.referral_source;
    }
  }

  const validateAll = () => {
    _.forEach(state.fields, (field, key) => {
      field._touched = true;
      field.validate();
    })
    if (phoneChanged && state.fields.phone._valid) {
      if (state.fields.otp.value === '') {
        state.fields.otp._errorMessage = 'Please fill in.';
        state.fields.otp._valid = false;
      } else {
        state.fields.otp._valid = true;
        state.fields.otp._errorMessage = '';
      }
    }
  }

  const startOtpTimer = () => {
    state.otpCooldown = 60;
    timer = setInterval(() => {
      if (state.otpCooldown <= 0) {
        clearInterval(timer);
      } 
      state.otpCooldown = state.otpCooldown - 1;
    }, 1000);
  }

  const getOtp = async () => {
    startOtpTimer();
    let response = await API.get('/jv/v1/otp/'+state.fields.phone.value, {
      phone: state.fields.phone.value,
      purpose: 'registration'
    })
    if (!response.ok) {
      let error = await response.json();
      if (error.code === "exists") {
        existingPhoneNums.push(state.fields.phone.value);
        clearInterval(timer);
        state.otpCooldown = 0;
      }
      alert(error.message);
    }
  }

  const isExisting = (phone:string) => {
    return _.includes(existingPhoneNums, phone)
  }

  useLayoutEffect(() => {
    if (user.info) {
      updateFields();
    }
  }, [user.info])

	return (
		<DefaultMagneticComponentsProvider value={{ Textbox, Dropdown }}>
			<>
				<MagneticForm fields={state.fields}>
					<div className={phoneChanged && state.fields.phone._valid ? 'ProfileForm__grid others':'ProfileForm__grid'}>
            <Button 
              block 
              color='primary' 
              className='grid-send-otp text-uppercase rounded-0'
              disabled={
                !phoneChanged ||
                !state.fields.phone._valid ||
                state.otpCooldown > 0
              }
              onClick={getOtp}
            >
              {state.otpCooldown > 0 ? 
                <small style={{textTransform: 'none', color: 'black', display: 'block'}}>You can retry in {state.otpCooldown} seconds.</small> 
                : 'SEND OTP'
              }
            </Button>

						<MagneticTextbox  name='name'   label='Name'  asterisk                              className='grid-name' />
						<MagneticTextbox  name='phone'  label='Phone' asterisk                              className='grid-phone' />
            {phoneChanged && state.fields.phone._valid && <MagneticTextbox  name='otp'    label='OTP'   asterisk className='grid-otp' />}
            <MagneticTextbox  name='email'  label='Email' asterisk                              className='grid-email' />
						<MagneticDropdown name='bMonth' label='Birth Month'                              className='grid-bMonth disabled' />
						<MagneticDropdown name='bYear'  label='Birth Year'                               className='grid-bYear' />
						<MagneticDropdown name='gender' label='Gender'                                      className='grid-gender' />
						<MagneticDropdown name='area'   label='Which area do you stay?'                     className='grid-area' />
						<MagneticDropdown name='diet'   label='Dietary Preference'                          className='grid-diet' />
						<MagneticDropdown name='know'   label='How do you know about our loyalty program' className='grid-know' />
					</div>
          {knowIsOthers && <MagneticTextbox  name='others'   label='Please specify'  className='mb-3' />}
				</MagneticForm>
        <p className='mb-1 mt-0'>Get updated on members’ privileges and promotions via the following ways:</p>
        <div className='comm-prefs mb-3'>
					<FakeCheckbox clickLabel label='SMS' checked={state.bySms} onClick={() => (state.bySms = !state.bySms)} />
					<FakeCheckbox clickLabel label='Email' checked={state.byEmail} onClick={() => (state.byEmail = !state.byEmail)} />
				</div>
        <Button
					block
					color='primary'
					onClick={async () => {
            validateAll();
						const success = await submit();
						if (success) {
							location.reload();
						}
					}}
				>
					SUBMIT
				</Button>
			</>
		</DefaultMagneticComponentsProvider>
	);
});


const Textbox: ComponentType<Record<string, any>> = props => (
	<div className={`position-relative w-100 ${props.className ?? ''}`}>
		<Label className={`label-box w-100 px-2 mb-0`}>
			{props.label}
			{props.asterisk && <span className='text-danger'>*</span>}
			<AttractedInput className='form-control pl-0' />
		</Label>

		<FormText color='danger' className='position-absolute' style={{ bottom: -20, left: 0, right: 0, whiteSpace: 'nowrap' }}>
			<AttractedErrorMessage />
		</FormText>
	</div>
);

const Dropdown: ComponentType<Record<string, any>> = props => (
	<div className={`position-relative w-100 ${props.className ?? ''}`}>
		<Label className={`label-box w-100 px-2 mb-0`}>
			<span style={{whiteSpace:'nowrap'}}>{props.label}</span>
			{props.asterisk && <span className='text-danger'>*</span>}
			<AttractedSelect className='form-control pl-0 custom-select blend-in' />
		</Label>

		<FormText color='danger' className='position-absolute' style={{ bottom: -20, left: 0, right: 0 }}>
			<AttractedErrorMessage />
		</FormText>
	</div>
);
