import React, { useLayoutEffect, useState } from "react"
import { useUserContext } from "../common/UserContext";

type Transaction = {
  branch_title: string
  staff_name: string
  total_bill: string
  total_paid_cash: string
  transaction_datetime: string
  transaction_id: string
  transaction_no: string
  transaction_status: string
}

export const TransactionsPage = () => {
  const user = useUserContext(true);
  const [transactions, setTransactions] = useState([]);

  useLayoutEffect(() => {
    if (user.info) {
      setTransactions(user.info.transactions.filter((t: Transaction) => t.transaction_status === 'active'));
    }
  }, [user.info])
  
  return (
    <div>
      <h3 className="mt-3 pb-2">My Transactions</h3>
      <div className="transaction-container">
        {transactions.length ? transactions.map((t: Transaction) => (
          <div key={t.transaction_id} className="transaction">
            <div className="transaction-title">
              <span>{t.branch_title}</span>
              <span className="ml-3">${t.total_bill}</span>
            </div>
            <div>Transaction No.: {t.transaction_no}</div>
            <div>{t.transaction_datetime}</div>
          </div>
        )): (
          <h5 className="mt-3">You have no active transactions.</h5>
        )}
      </div>
    </div>
  )
}