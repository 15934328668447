export const API_URL = process.env.API_URL;
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DISPLAY_TIME_FORMAT = 'hh:mm A';
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY';

export const API_DATETIME_FORMAT = `${API_DATE_FORMAT} ${API_TIME_FORMAT}`;
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
