import React, { useState, useLayoutEffect } from "react"
import { InputGroup, InputGroupAddon, Input, FormText, Button } from "reactstrap";
import { LabeledInputState } from './../common/LabeledInput';
import { requiredPhoneNum, requiredMin4Char, requiredStr } from "../common/Validations";
import { observable, reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { API_URL, DATETIME_FORMAT } from './../common/Constants';
import moment from "moment";
import { API } from "../common/API";
import { load } from "recaptcha-v3";
import _ from "lodash";
import { basepath } from './../common/API';

const kfgLogo: string = require('../../images/KFG-Logo-placeholder.png');
const phone: string = require('../../images/phone.svg');
const lock: string = require('../../images/lock.svg');

class ResetPageState {
  mobile = new LabeledInputState(requiredPhoneNum);
  password = new LabeledInputState(requiredMin4Char);
  password_c = new LabeledInputState(requiredMin4Char);
  otp = new LabeledInputState(requiredStr); 
  @observable step = 1;
  @observable fromStore = false;
  @observable otpCooldown = 0;

  constructor() {
    reaction(
			() => [this.password.value, this.password_c.value],
			([pass, pass_c]) => {
        if (pass.length > 0 && pass_c.length > 0 && pass !== pass_c) {
          this.password_c.overrideError = 'Passwords do not match.';
				} else {
          this.password_c.overrideError = null;
        }
			}
		);
  }

  get isValid(): boolean {
		return _.every([this.mobile, this.password, this.password_c, this.otp], f => (f.validate(), !f.handler.invalid));
  }

  get isMobileValid(): boolean {
    return this.mobile.validate(), !this.mobile.invalid;
  }

  validateAll = () => {
    [this.mobile, this.password, this.password_c, this.otp].map(f => {
      f.touched = true;
      f.validate();
    })
  }
  
  getResetToken = async () => {
    const token = sessionStorage.getItem('resetToken');
		const expiry = sessionStorage.getItem('resetTokenExpiry') || moment().format(DATETIME_FORMAT);
		if (token && moment().isBefore(moment(expiry, DATETIME_FORMAT))) {
			return token;
		} else {
      let response = await API.fetchTokenForSignup();
			if (response && response.ok) {
				let res = await response.json();
				sessionStorage.setItem('resetToken', res.access_token);
				sessionStorage.setItem(
					'resetTokenExpiry',
					moment()
						.add(res.expires_in, 's')
						.format(DATETIME_FORMAT)
				);
				return res.access_token;
			} else {
				alert('An error occurred');
			}
    }
  }

  submit = async () => {
    if (!this.isValid) return false;

    const reCaptcha = await load('6LdKF7EUAAAAANusPfnkXy8FCmV9pIyz7RVtOmYk');
    const rToken = await reCaptcha.execute('submit');
    const data = {
      phone: this.mobile.value, 
      password: this.password.value, 
      otp: this.otp.value, 
      'g-recaptcha-response': rToken
    }
    const res = await fetch(`${API_URL}jv/v1/members/me/password`, {
      method: 'POST',
			headers: {
				Authorization: `Bearer ${await this.getResetToken()}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
    });
    if (!res.ok) {
      const result = await res.json();
			alert(result.message);
		} else {
      sessionStorage.removeItem('resetToken');
      sessionStorage.removeItem('resetTokenExpiry');
      alert(`Password updated.`);
      window.location.href = basepath`/`;
		}
		return res.ok;
  }
}

export const ResetPage = observer(() => {
  const [state] = useState<ResetPageState>(() => new ResetPageState());

  const next = () => {
    state.mobile.validate();
    if (!state.mobile.invalid) {
      getOtp();
    }
  }

  const startOtpTimer = () => {
    state.otpCooldown = 60;
    let timer = setInterval(() => {
      if (state.otpCooldown <= 0) {
        clearInterval(timer);
      } 
      state.otpCooldown = state.otpCooldown - 1;
    }, 1000);
  }

  const getOtp = async () => {
    startOtpTimer();
    load('6LdKF7EUAAAAANusPfnkXy8FCmV9pIyz7RVtOmYk').then(reCaptcha => {
			reCaptcha.execute('getOtp').then(async rToken => {
				let response = await fetch(
					`${API_URL}jv/v1/otp/${state.mobile.value}?phone=${state.mobile.value}&g-recaptcha-response=${rToken}&purpose=reset-password`,
					{
						headers: {
							Authorization: `Bearer ${await state.getResetToken()}`,
						},
					}
				);
				if (!response.ok) {
          let error = await response.json();
          if (error.code === 'not_found') {
            state.mobile.errorMessage = 'Member account not found.  Please confirm mobile number, or proceed to <a href="./signup">register</a>.';
            state.mobile.invalid = true;
          }
				} else {
          state.step = 2;
        }
			});
		});
  }


  useLayoutEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get('id')) {
      state.mobile.value = params.get('id')!;
      state.fromStore = true;
    }
    // sessionStorage.clear();
  },[])
  
  return (
    <div className="forget-container">
      <div className="logo-container">
        <img src={kfgLogo} alt="" />
      </div>
      <div className="rewards">REWARDS</div>
      <h4 className="my-4">Reset Password</h4>
      <div>STEP {state.step} OF 2</div>
      {state.step === 1 && (
        <>
          <div>Please enter the mobile number of the registered account.</div>
          <div className="reset-form">
            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
              <InputGroup>
                <InputGroupAddon className='mobile-prepend' addonType='prepend'>
                  +65
                </InputGroupAddon>
                <Input name='username' 
                  className='form-control login-mobile' 
                  placeholder='mobile number' 
                  value={state.mobile.value} 
                  onChange={e=>state.mobile.handler.onChange(e.target.value) }
                  onBlur={state.mobile.handler.onBlur}
                  disabled={state.fromStore}
                />
                <InputGroupAddon addonType='append'>
                  <div className='phone-container'>
                    <img src={phone} />
                  </div>
                </InputGroupAddon>
              </InputGroup>
              <FormText color='danger' className="text-left">
                {state.mobile.handler.invalid ? <span dangerouslySetInnerHTML={{__html:state.mobile.handler.errorMessage}}></span> : ''}
              </FormText>
            </form>
            <Button color="primary" block className="mt-4" onClick={next} disabled={!state.isMobileValid}>
              NEXT
            </Button>
          </div>
        </>
      )}
      {state.step === 2 && (
        <>
          <div>Enter the Temporary Pin sent to your mobile number and the new password.</div>
          <div className="reset-form">
            <form autoComplete="off" onSubmit={e => e.preventDefault()} className="otp">
              <Input 
                name='otp' 
                autoComplete="off" 
                placeholder="OTP"
                maxLength={4}
                value={state.otp.value}
                onChange={e=>state.otp.handler.onChange(e.target.value)}
                onBlur={state.otp.handler.onBlur}
              />
              <div 
                className={`btn btn-primary btn-block${state.otpCooldown > 0 ? ' disabled':''}`}
                onClick={() => state.otpCooldown <= 0 && getOtp()}
              >
              {state.otpCooldown > 0 ? 
                <small style={{textTransform: 'none', color: 'black', display: 'block'}}>Please wait {state.otpCooldown} seconds</small> 
                : 'RESEND OTP'
              }
              </div>
            </form>
            <FormText color='danger' className='text-left'>
              {state.otp.handler.invalid ? <span dangerouslySetInnerHTML={{__html:state.otp.handler.errorMessage}}></span> : ''}
            </FormText>
            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
              <InputGroup className="mt-3">
                <Input 
                  type="password" 
                  placeholder="New Password" 
                  value={state.password.value}
                  onChange={e=>state.password.handler.onChange(e.target.value)}
                  onBlur={state.password.handler.onBlur}
                />
                <InputGroupAddon addonType='append'>
                  <div className='phone-container'>
                    <img src={lock} />
                  </div>
                </InputGroupAddon>
              </InputGroup>
              <FormText color='danger' className='text-left'>
                {state.password.handler.invalid ? <span dangerouslySetInnerHTML={{__html:state.password.handler.errorMessage}}></span> : ''}
              </FormText>
            </form>
            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
              <InputGroup className="mt-3">
                <Input 
                  type="password" 
                  placeholder="Re-enter Password"
                  value={state.password_c.value}
                  onChange={e=>state.password_c.handler.onChange(e.target.value)}
                  onBlur={state.password_c.handler.onBlur}
                />
                <InputGroupAddon addonType='append'>
                  <div className='phone-container'>
                    <img src={lock} />
                  </div>
                </InputGroupAddon>
              </InputGroup>
              <FormText color='danger' className='text-left'>
                {state.password_c.handler.invalid ? <span dangerouslySetInnerHTML={{__html:state.password_c.handler.errorMessage}}></span> : ''}
              </FormText>
            </form>
            <Button 
              color="primary"
              block
              className="mt-3" 
              onClick={e => {
                state.validateAll();
                state.submit()
              }}
              disabled={!state.isValid}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
      <a className="mt-5" href="./login">
        { (sessionStorage.token) ? 'Cancel' : 'Back to Login' } 
      </a>
    </div>
  )
});