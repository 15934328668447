export const commaify = (x: number) => {
	var parts = x.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return parts.join('.');
};

const formatNumber = (n: string) => {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatCurrency = (x: string) => {
  if (x === "") { return ''; }
  if (x.indexOf(".") >= 0) {
    var decimal_pos = x.indexOf(".");
    var left_side = x.substring(0, decimal_pos);
    var right_side = x.substring(decimal_pos);
    left_side = formatNumber(left_side);
    right_side = formatNumber(right_side);
    right_side = right_side.substring(0, 2);
    x = "$" + left_side + "." + right_side;
  } else {
    x = formatNumber(x);
    x = "$" + x;
  }
  return x;
}

export const currency2number = (x: string) => {
  return x.replace(/[^0-9.-]+/g,"");
}
