import 'babel-polyfill';
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from './Controller';
import './index.scss';

library.add(far, fas);

ReactDOM.render(<Controller />, document.getElementById('app'));
