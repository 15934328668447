import React, { useState } from "react"
import { API, basepath } from './../common/API';
import { useAsyncEffect } from "../common/useAsyncEffect";
import moment from "moment";
import { API_DATETIME_FORMAT, DATE_FORMAT_SLASH } from './../common/Constants';
import { Deal } from "./Deal";

export type DealType = {
  ID: number,
  code: string,
  datetime_from: string,
  datetime_to: string,
  description: string,
  featured: boolean,
  name: string,
  promo_code: string,
  tnc: string,
  image: string
}

export const DealsPage = () => {
  const [deals, setDeals] = useState<DealType[]>();
  const [show, setShow] = useState();
  
  useAsyncEffect(async () => {
    const res = await API.get('/jv/v1/deals');
    if (res.ok) {
      let data = await res.json();
      setDeals(Object.values<DealType>(data));
    } else {
      window.location.href = basepath`/logout`;
    }
  }, []);

  return (
    <div>
      <h3 className="mt-3 pb-2">Deals &amp; Offers</h3>
      <div className="deals-container">
        {deals && deals.map((deal) => (
          <div className="deal" key={deal.ID} onClick={()=>setShow(deal)}>
            <div className={deal.image ? "deal-image" : "deal-image no-image"}>
              {deal.image ? (
                <img src={deal.image+""} alt=""/>
              ) : (
                deal.name
              )}
            </div>
            <h4>{deal.name}</h4>
            <p className="deal-valid">
              Valid from {moment(deal.datetime_from, API_DATETIME_FORMAT).format(DATE_FORMAT_SLASH)} to {moment(deal.datetime_to, API_DATETIME_FORMAT).format(DATE_FORMAT_SLASH)}
            </p>
          </div>
        ))}
        <Deal show={show} setShow={setShow} />
      </div>
    </div>
  )
}
