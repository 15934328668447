import React, { ComponentType } from 'react';
import { Navbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const kfgLogo: string = require('../../images/KFG-Logo-placeholder.png');

export const Topbar: ComponentType<{ className?: string, showMenu: boolean, setShowMenu: (showMenu:boolean) => void }> = props => (
	<>
		<Navbar className={props.className}>
      <div className="menu-toggle" onClick={() => props.setShowMenu(!props.showMenu)}>
        <FontAwesomeIcon icon={faBars} size="2x"/>
      </div>
			<span className='navbar-brand mb-0 d-inline-flex align-items-center mr-auto' >
        <a href={process.env.BASE_PATH}>
          <img src={kfgLogo} height='30'/>
        </a>
        <span className='mt-1 mb-0 font-weight-normal text-uppercase rewards-title'>Rewards</span>
			</span>
		</Navbar>
	</>
);