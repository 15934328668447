import { useEffect } from 'react';

export const useAsyncEffect = (callback: () => void | Promise<void | (() => void | undefined)>, deps?: readonly any[]) => {
	useEffect(() => {
		const result = callback();

		return () => {
			result && result.then(dispose => dispose && dispose());
		};
	}, deps);
};
