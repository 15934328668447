import React, { useState, useLayoutEffect } from "react"
import { useAsyncEffect } from './../common/useAsyncEffect';
import { API, basepath } from './../common/API';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useUserContext } from "../common/UserContext";

type Reward = {
  ID: string;
  description: string;
  effective_from: string;
  effective_to: string;
  image: string;
  name: string;
  quantity_redeemed: string;
  required_points: string;
  tnc: string;
  total_quantity: string;
  type_name: string;
  type_slug: string;
}

type Points = {
  balance: number;
  expires_on: string;
}

export const CataloguePage = () => {
  const [rewards, setRewards] = useState();
  const [show, setShow] = useState();
  const user = useUserContext(true);
  const [points, setPoints] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  const enoughPoints = (requiredPoints:string) => {
    let rp = Number(requiredPoints) === NaN ? 0 : Number(requiredPoints);
    return points >= rp;
  }

  const redeemVoucher = async () => {
    let response = await API.post('/jv/v1/members/me/vouchers', {reward_id: show?.ID});
    if (response.ok) {
      await user.getUserData();
      setShowSuccess(true);
      setShowConfirm(false);
    } else {
      let err = await response.json();
      setShowConfirm(false);
      setShow(undefined);
      setError(err.message);
      setShowError(true);
    }
  }

  const countPoints = () => {
    let p = user.info.points
      .map((o: Points) => o.balance || 0)
      .reduce((acc:number, val:number) => acc + val, 0);
    setPoints(p);
    console.log
  }

  useLayoutEffect(() => {
    console.log('user info changed');
    if (user.info) {
      countPoints();
    }
  }, [user.info])

  useAsyncEffect(async ()=>{
    let response = await API.get('/jv/v1/rewards');
    if (response.ok) {
      let data = await response.json();
      setRewards(Object.values(data))
    } else {
      window.location.href = basepath`/logout`;
    }
  }, []);

  return (
    <div>
      <div className="mt-3 pb-2 catalogue-heading">
        <h2>Rewards Catalogue</h2>
        <span>Available points: {points}</span>
      </div>
      <div className="deals-container">
        {rewards && rewards.map((reward: Reward) => (
          <div className="deal" key={reward.ID} onClick={()=>setShow(reward)}>
            <div className={reward.image ? "deal-image" : "deal-image no-image"}>
              {reward.image ? (
                <img src={reward.image} alt=""/>
              ) : (
                reward.name
              )}
            </div>
            <h4>{reward.name}</h4>
            <p>{reward.required_points.length ? reward.required_points : '0'} points</p>
          </div>
        ))}
        <Modal isOpen={show != undefined} toggle={()=>setShow(undefined)}>
          <ModalHeader toggle={()=>setShow(undefined)} />
          <ModalBody>
            <h4>{show?.name}</h4>
            <div className={show?.image ? "deal-image" : "deal-image no-image"}>
              {show?.image ? (
                <img src={show?.image+""} alt=""/>
              ) : (
                show?.name
              )}
            </div>
            <div className="mb-2" dangerouslySetInnerHTML={{__html: show?.description}}></div>
            <h5>Terms &amp; Conditions</h5>
            <div className="mb-2" dangerouslySetInnerHTML={{__html:show?.tnc}}></div>
            <div 
              className={`btn btn-primary with-points${!enoughPoints(show?.required_points) ? ' disabled':''}`}
              onClick={()=> {
                if (enoughPoints(show?.required_points)) {
                  setShowConfirm(true)
                }
              }}
            >
              <div className="points">
                <span>
                  {show?.required_points ? show?.required_points:'0'} POINTS
                </span>
              </div>
              <span className="btn-text">REDEEM</span>
            </div>
            {!enoughPoints(show?.required_points) && <small className="text-danger">You do not have sufficient points to redeem this voucher.</small>}
          </ModalBody>
        </Modal>
        <Modal isOpen={showConfirm} centered>
          <ModalBody>
            <div className="text-center">
              <h4>Confirm Redemption?</h4>
              <p>{show?.required_points ? show?.required_points:'0'} points will be used.</p>
              <div className="btn-container">
                <div className="btn btn-secondary" onClick={()=>setShowConfirm(false)}>BACK</div>
                <div className="btn btn-primary" onClick={()=>redeemVoucher()}>REDEEM</div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showSuccess} centered toggle={()=>setShowSuccess(!showSuccess)}>
          <ModalBody>
            <h5>Voucher has been successfully redeemed. You can find your voucher under <a href="./rewards">My Rewards</a>.</h5>
          </ModalBody>
        </Modal>
        <Modal isOpen={showError} centered>
          <ModalBody>
            <div className="text-center">
              <h4>There was an error:</h4>
              <h5>{error}</h5>
              <div className="btn btn-primary" onClick={()=>setShowError(!showError)}>CLOSE</div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}