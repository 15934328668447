import { DATE_FORMAT, API_DATETIME_FORMAT } from "../common/Constants"
import moment = require("moment")
import React, { useState } from "react"
import { VoucherType } from "./RewardsPage"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

type VoucherProps = {
  voucher: VoucherType
}

export const Voucher = (props: VoucherProps) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div className="voucher-container" key={props.voucher.voucher_no} onClick={() => setShowDetail(!showDetail)}>
      <div className="voucher-image">
        {props.voucher.image ? (
          <img src={props.voucher.image+""} alt=""/>
        ) : (
          props.voucher.title
        )}
      </div>
      <div className="voucher-detail">
        <span className="font-weight-bold">{props.voucher.title}</span>
        <div className="expiry">
          <span>Effective From {moment(props.voucher.effective_from, API_DATETIME_FORMAT).format(DATE_FORMAT)} to {moment(props.voucher.expires_on, API_DATETIME_FORMAT).format(DATE_FORMAT)}</span>
        </div>
        <small>Terms & Conditions Apply</small>
      </div>
      <Modal className="voucher-modal" isOpen={showDetail} toggle={()=>setShowDetail(!showDetail)}>
        <ModalHeader toggle={()=>setShowDetail(!showDetail)}>
        </ModalHeader>
        <ModalBody>
          <div className="voucher-detail">
            <h3>{props.voucher.title}</h3>
            <div className="expiry mb-3">
              <span style={{'fontSize':'0.98rem'}}>Effective From {moment(props.voucher.effective_from, API_DATETIME_FORMAT).format(DATE_FORMAT)} to {moment(props.voucher.expires_on, API_DATETIME_FORMAT).format(DATE_FORMAT)}</span>
            </div>
            <div className="voucher-image mb-3">
              {props.voucher.image ? (
                <img src={props.voucher.image+""} alt=""/>
              ) : (
                props.voucher.title
              )}
            </div>
            <p dangerouslySetInnerHTML={{__html: props.voucher.description}}></p>
            <h4 className="mt-5">Terms &amp; Conditions</h4>
            <p dangerouslySetInnerHTML={{__html: props.voucher.tnc}}></p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}