import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactNode } from 'react';

export const FakeCheckbox: FC<{ label?: ReactNode; onClick: () => void; checked: boolean; clickLabel?: boolean }> = props => (
	<span className={`d-flex mr-5 ${props.clickLabel ? 'cursor-pointer' : ''}`} onClick={props.clickLabel ? props.onClick : void 7}>
		<div className='cursor-pointer mr-2' onClick={props.clickLabel ? void 7 : props.onClick}>
			<Checkmark checked={props.checked} />
		</div>

		<div>{props.label || props.children}</div>
	</span>
);

export const Checkmark: FC<{ checked?: boolean }> = props => <FontAwesomeIcon fixedWidth icon={props.checked ? faCheckSquare : faSquare} />;
