import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentType, useState, useLayoutEffect } from 'react';
import { Col, Container, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Barcode } from './Barcode';
import { Navigation } from './Navigation';
import { Topbar } from './Topbar';
import { useUserContext } from './UserContext';
import { ProfileForm } from '../members/ProfileForm';

export const Layout: ComponentType = props => {
	const user = useUserContext(true);
  const [width, setWidth] = useState(window.innerWidth > 0 ? window.innerWidth : screen.width);
  const [showMenu, setShowMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  window.onresize = () => {
    setWidth(window.innerWidth > 0 ? window.innerWidth : screen.width);
  }

  useLayoutEffect(()=> {
    if (width >= 768) {
      setShowMenu(true);
    }
  }, [width])
	return (
		<>
			<div className='Base__layout'>
				<Topbar className='Base__topbar' showMenu={showMenu} setShowMenu={setShowMenu}/>
				<Container fluid className='Base__container'>
					<Row className='align-items-stretch'>
						<Col md='4' lg='3' className={showMenu ? 'pt-3 dark-border-right drawer':'drawer hidden' }>
							<div className='dark-border-bottom mx-n3 p-4'>
								<h3 className='pb-2'>
									Hi {user.info?.name ?? user.name} <FontAwesomeIcon className='text-muted float-right cursor-pointer' icon={faEdit} onClick={()=> setShowProfile(!showProfile)}/>
								</h3>
								<div className='barcode-container'>
									<Barcode content={user.info?.membership_no ?? ''} />
									<div className='barcode-label'>{user.info?.membership_no ?? ''}</div>
								</div>
							</div>
              <Modal isOpen={showProfile}>
                <ModalHeader toggle={()=>setShowProfile(!showProfile)}>
                  Update Profile
                </ModalHeader>
                <ModalBody>
                  <ProfileForm/>
                </ModalBody>
              </Modal>

							<Navigation />
						</Col>
						<Col md='8' lg='9'>
							{props.children}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};


